@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: "IQOS-Bold";
    src: url("assets/fonts/IQOS/IQOS-Bold.ttf");
    font-display: swap;
}

@font-face {
    font-family: "IQOS-Light";
    src: url("assets/fonts/IQOS/IQOS-Light.ttf");
    font-display: swap;
}

@font-face {
    font-family: "IQOS-Regular";
    src: url("assets/fonts/IQOS/IQOS-Regular.ttf");
    font-display: swap;
}